<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-bullet-list-67"></i> Uji Kalibrasi
      </h6>
    </h1>
    
    <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card style="position: relative;overflow: hidden;">

              <h3>Cari Berdasarkan</h3>
              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">

                <!-- Tanggal -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                  <label><small>Tanggal</small></label>
                  <base-input name="Tanggal">
                    <flat-picker
                      placeholder="Filter Tanggal"
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggal"
                      style="background:white;"
                    />
                  </base-input>
                </div>

                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;"   v-if="log_as == 'admin'">
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.spot_name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
                
              </div>
              

              <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
              <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
            </b-card>
          </b-col>
        </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>

            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
              <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end" class="mb-3">
                <!-- <button
                  class="btn text-white btn-sm"
                  style="background-color: #b51308;"
                  @click="downloadPDFNow"
                >
                  <i class="fa fa-solid fa-file-pdf mr-2" style="font-size: 20px;"></i>
                    Unduh PDF
                </button> -->
                <button
                  class="btn text-white btn-sm"
                  style="background-color: #0a7641;"
                  @click="downloadExcelNow"
                >
                  <i class="fa fa-solid fa-file-excel mr-2" style="font-size: 20px;"></i>
                    Unduh Excel
                </button>
                  <button class="btn btn-secondary" @click="handleAdd" v-if="log_as != 'admin'">+ Tambah Uji Kalibrasi</button>
              </b-col>
            </b-row>

            <section id="mytable">
              <el-table
                :data="listData"
                row-key="id"
                header-row-class-name="thead-light"
              >
                <el-table-column label="No" type="index" width="100" />

                <el-table-column min-width="150px"  label="Nama Industri" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.comp_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="150px"  label="Nama Titik Penaatan">
                  <div slot-scope="action">
                  {{ action.row.spot_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="140px"  label="Jenis Industri" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.comp_type_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="110px"  label="Provinsi" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.province_name}}
                  </div>
                </el-table-column>

                <el-table-column min-width="130px"  label="Kabupaten / Kota" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.kabkot_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="120px"  label="Tanggal Uji">
                  <div slot-scope="action">
                    {{ formatDate(action.row.created_at) }}
                  </div>
                </el-table-column>

                <el-table-column min-width="70px"  label="Periode Uji">
                  <div slot-scope="action">
                    {{ action.row.quarter}}
                  </div>
                </el-table-column>

                <el-table-column min-width="120px"  label="Aksi">
                  <div slot-scope="action">
                    <button class="btn btn-primary btn-sm" @click="seeDetail(action.row)">RINCIAN</button>
                  </div>
                </el-table-column>

              </el-table>

              <div class="py-3">
                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                  Showing {{ 1 }} to {{ listData.length }} of
                  {{ pagination.total }} entries
                </small>
              </div>

              <div style="display: flex; justify-content: flex-end">
                <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
                >
                </base-pagination>
              </div>
            </section>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal Rincian -->
    <Modal
      :show="showDetailModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="closeModal"
      size="xl">
      <template v-slot:header>
        <div class="modal-title border-bottom pb-4 w-100">
          <h2 class="ml-3">Detail Uji Kalibrasi</h2>
        </div>
      </template>

      <div class="w-100 col-12">
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Nama Industri</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailCompanyName }}
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Nama Titik Penaatan</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailStation }}
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Jenis Industri</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailCompanyType }}
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Provinsi</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailProvince }}
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Kab/Kota</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailRegency }}
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Tanggal</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailDate }}
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-2 col-form-label form-control-label">Periode</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ detailPeriode }}
          </div>
        </div>
        <div class="font-weight-bolder mb-2">Sensor</div>
        <details v-for="(item, idx) in sensorData" :key="idx" open>
          <summary>Sensor {{ item.parameter }}</summary>
          <div class="form-group row mb-0">
            <label class="col-lg-2 col-form-label form-control-label">Brand</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              {{ item.brand }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-lg-2 col-form-label form-control-label">Tipe</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              {{ item.brand_type }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-lg-2 col-form-label form-control-label">Range</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              {{ item.min }} - {{ item.max }}
            </div>
          </div>
          
          <div class="form-group row mb-0">
            <label class="col-lg-2 col-form-label form-control-label">Periode kalibrasi</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              {{ }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-lg-2 col-form-label form-control-label">Tanggal kalibrasi</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              {{ item.calibration_date }}
            </div>
          </div>
          <div class="form-group row mb-0">
            <label class="col-lg-2 col-form-label form-control-label">Akurasi</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              {{ item.accuration }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-2 col-form-label form-control-label">File</label>
            <div class="col-lg-10 col-form-label font-weight-bold">
              <a
                :href="`${item.attachment_file_id}`"
                target="blank"
                class="rounded bg-primary p-2 text-white">
                Lihat file
              </a>
            </div>
          </div>
        </details>
        <div class="form-group row">
          <div class="col-lg-12">
            <div class="float-right">
              <button class="btn btn-warning" @click="closeModal">Tutup</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal Tambah Uji Kalibrasi -->
    <Modal
        :show="showAddModal"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        size="lg"
      >
        <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Tambah Uji Kalibrasi</h4>
          </div>
        </template>

        <div class="w-100 col-12">
        <div class="form-group row mb-0">
          <label class="col-lg-2 col-form-label form-control-label">Tanggal</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ getTimeNow() }}
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-2 col-form-label form-control-label">Periode</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
            {{ getQuarter() }}
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-2 col-form-label form-control-label">Pilih Titik Penaatan</label>
          <div class="col-lg-10 col-form-label font-weight-bold">
             <!-- Titik Penaatan -->
             <div>
              <base-input>
                  <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                    <el-option
                      v-for="option in listDataCompliencePoints"
                      :key="option.id"
                      :label="option.spot_name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
              </base-input>
            </div>
          </div>
        </div>
        

        <div class="form-group row">
          <div class="col-lg-12">
            <div class="float-right">
              <button class="btn btn-primary" @click="tambahUjiKalibrasi">BUAT UJI KALIBRASI</button>
              <button class="btn btn-danger" @click="batalUjiKalibrasi">BATAL</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal Tambah Uji Kalibrasi Sensor -->
    <Modal
        :show="showAddSensorModal"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        size="xl"
      >
        <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Daftarkan Sensor untuk di Uji Kalibrasi</h4>
          </div>
        </template>

        <div class="w-100 col-12">
          <button class="btn btn-primary mb-3" @click="addSensorNow">+ Tambah Sensor</button>
          <table class="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Sensor</th>
                <th>Brand</th>
                <th>Brand Type</th>
                <th>Akurasi</th>
                <th>Min</th>
                <th>Max</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in sensorRegistered" :key="idx">
                <td>{{ idx + 1 }}</td>
                <td>{{ item.parameter }}</td>
                <td>{{ item.brand }}</td>
                <td>{{ item.brand_type }}</td>
                <td>{{ item.accuration }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.max }}</td>
                <td>
                  <button class="btn btn-sm btn-warning" @click="populatedSensorForUpdate(item)">Update</button>
                  <button class="btn btn-sm btn-danger" @click="willDeleteSensor(item)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>


        <div class="form-group row">
          <div class="col-lg-12">
            <div class="float-right">
              <button class="btn btn-danger" @click="cancelUjiKalibrasiRegistered">BATAL</button>
              <button class="btn btn-secondary" @click="SubmitujiKalibrasiRegistered" >SELESAI</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Tambah Sensor -->
    <Modal
        :show="showAddSensor"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        size="xl"
      >
     

        <div class="w-100 col-12">


          <div class="p-5">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
              <b-form role="form" @submit.prevent="handleSubmit(!idWillGetUpdate?submitSensor:sensorForUpdate)">
                <div class="form-group row mb-0">
                  <label class="col-lg-2 col-form-label form-control-label">Tipe</label>
                  <div class="col-lg-10 col-form-label font-weight-bold">
                    <base-input name="Tipe Sensor" :rules="{required: true}">
                      <el-select filterable placeholder="Sensor" v-model="sensorType">
                        <el-option
                          v-for="(option, idx) in sensorName"
                          :key="idx"
                          :label="option"
                          :value="option"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <label class="col-lg-2 col-form-label form-control-label">Brand</label>
                  <div class="col-lg-10 col-form-label font-weight-bold">
                    <div class="form-group row">
                      <div class="col-lg-12">
                      <base-input name="Brand" v-model="brand" :rules="{required: true}"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <label class="col-lg-2 col-form-label form-control-label">Tipe</label>
                  <div class="col-lg-10 col-form-label font-weight-bold">
                    <div class="form-group row">
                      <div class="col-lg-12">
                      <base-input name="Tipe" v-model="brandType" :rules="{required: true}"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <label class="col-lg-2 col-form-label form-control-label">Range</label>
                  <div class="col-lg-4 col-form-label font-weight-bold">
                    <div class="form-group row">
                      <div class="col-lg-12">
                      <base-input type="number" name="min" v-model="min" :rules="{required: true}"/>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-form-label font-weight-bold">
                    <div class="form-group row">
                      <div class="col-lg-12">
                      <base-input type="number" name="max" v-model="max" :rules="{required: true}"/>
                      </div>
                    </div>
                  </div>
                </div>
                
              
                <div class="form-group row mb-0">
                  <label class="col-lg-2 col-form-label form-control-label">Tanggal kalibrasi</label>
                  <div class="col-lg-10 col-form-label font-weight-bold">
                    <div class="form-group row">
                      <div class="col-lg-12">
                        <base-input
                        class="m-0"
                        rules="required"
                        name="Tanggal Kalibrasi"
                      >
                      <flat-picker
                        name="Tanggal Kalibrasi"
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="provideSelect.flatPickerConfig"
                        class="form-control datepicker"
                        v-model="tanggalKalibrasi"
                      ></flat-picker>
                      </base-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <label class="col-lg-2 col-form-label form-control-label">Akurasi</label>
                  <div class="col-lg-10 col-form-label font-weight-bold">
                    <div class="form-group row">
                      <div class="col-lg-12">
                      <base-input type="number" name="Akurasi" v-model="akurasi" :rules="{required: true}"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-lg-2 col-form-label form-control-label">File</label>
                  <div class="col-lg-10 col-form-label font-weight-bold">
                    <base-input name="File" :key="fileSensor">
                      <file-input
                        accept="application/pdf"
                        ref="uji_kalibrasi_attachment"
                        @change="uploadFile('uji_kalibrasi_attachment', 'uji_kalibrasi_attachment')" />
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="float-right">
                      <button class="btn btn-secondary" native-type="submit" v-if="!idWillGetUpdate">KIRIM</button>
                      <button class="btn btn-secondary" native-type="submit" v-if="idWillGetUpdate">PERBAHARUI SENSOR</button>
                      <button class="btn btn-danger" @click="showAddSensor = false">BATAL</button>
                    </div>
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </div>

      

      </div>
    </Modal>

    <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal PDF -->
    <Modal
      :show="showDownloadModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModal = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="height: 500px; overflow: scroll">
            <div id="printBos">
              <div id="headerpdf">
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div style="width: 50px; height: 50px">
                        <img
                          src="/img/brand/img-logo-color.png"
                          alt=""
                          style="width: 100%"
                        />
                      </div>
                      <h3 class="ml-3">Kementerian Lingkungan Hidup</h3>
                    </div>
                  </div>
                  <div>
                    <h2>{{ pdfName }}</h2>
                    <small><b>Laporan Perjam</b></small
                    ><br />
                    <small>Data ini dibuat pada 12-20-2024</small>
                  </div>
                </div>
                <table class="table mt-3">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white" rowspan="2" >No</th>
                      <th class="text-white" rowspan="2">Nama Industri</th>
                      <th class="text-white" rowspan="2">Jenis Industri</th>
                      <th class="text-white" rowspan="2">Provinsi</th>
                      <th class="text-white" rowspan="2">Kabupaten / Kota</th>
                      <th class="text-white" rowspan="2">Jenis Sensor</th>
                      <th class="text-white" rowspan="2">Brand Sensor</th>
                      <th class="text-white" rowspan="2">Tipe Sensor</th>
                      <th class="text-white" rowspan="2">Akurasi Sensor</th>
                      <th class="text-white" rowspan="2">Waktu Kalibrasi</th>
                      <th class="text-white" colspan="2">Rentang Ukur</th>
                      <th class="text-white" rowspan="2">Jadwal Kalibrasi</th>
                    </tr>
                    <tr>
                      <th class="text-white" >Min</th>
                      <th class="text-white" >Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.company_name }}</td>
                      <td>{{ item.province_name}}</td>
                      <td>{{ item.regency_name }}</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                      <td>belum ada</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class="mt-5">
              <button class="btn btn-success" @click="exportExcel">Unduh Excel</button>
            </div> -->

            <!-- <ReportExcel/> -->
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-primary" @click="print">Unduh PDF</button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div>
              <div>

                <div style="padding:10px;overflow-x: scroll;" >
                  <table class="table mt-3" ref="exportable_table">
                    <thead class="bg-primary">
                    <tr>
                      <th class="text-white" rowspan="2" >No</th>
                      <th class="text-white" rowspan="2">Nama Industri</th>
                      <th class="text-white" rowspan="2">Jenis Industri</th>
                      <th class="text-white" rowspan="2">Provinsi</th>
                      <th class="text-white" rowspan="2">Kabupaten / Kota</th>
                      <th class="text-white" rowspan="2">Jenis Sensor</th>
                      <th class="text-white" rowspan="2">Brand Sensor</th>
                      <th class="text-white" rowspan="2">Tipe Sensor</th>
                      <th class="text-white" rowspan="2">Akurasi Sensor</th>
                      <th class="text-white" rowspan="2">Waktu Kalibrasi</th>
                      <th class="text-white" colspan="2">Rentang Ukur</th>
                      <th class="text-white" rowspan="2">Jadwal Kalibrasi</th>
                    </tr>
                    <tr>
                      <th class="text-white" >Min</th>
                      <th class="text-white" >Max</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in listDataDownload">
                        <td>{{ idx + 1 }}</td>
                        <td>{{ item.company_name }}</td>
                        <td>{{ item.company_type_name }}</td>
                        <td>{{ item.province_name}}</td>
                        <td>{{ item.regency_name }}</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                        <td>belum ada</td>
                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="ExportExcel('xlsx')"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import API from '../../../api/base_url'
import { Select, Option, Table, TableColumn } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from 'moment'
import FileInput from "@/components/Inputs/FileInput";

// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
components: {
  Modal,
  FileInput,
  BaseHeader,
  flatPicker,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [TableColumn.name]: TableColumn,
  Modal
},
mounted() {
  this.log_as = localStorage.getItem("as");
  this.getData(),
  this.getCompanyType()
  this.getCompanyName()
  this.getProvince()
  this.getCompliencePoint()
},
data() {
  return {
    idUjiKalibrasiFile:"",
    sensorRegistered: [],
    // add sensor form
    idWillGetUpdate:"",
    idReturn:"",
    sensorType: "",
    brand:"",
    brandType:"",
    min:"",
    max:"",
    tanggalKalibrasi:"",
    akurasi:"",
    // 
    fileSensor: 0,
    formData: new FormData(),
    sensorName: ['pH','COD','TSS','NH3N','Debit'],
    pdfName:"",
    listSensorUji: [],
    listDataDownload: [],
    listDataCompliencePoints: [],
    filterStatusPendaftaran: "",
    userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
    provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          defaultHour: 0,
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
    form: {
      umum: {
        compName: null,
        compNameID: null,
        compType: null,
        compCity: "",
        compProvince: "",
      },
    },
    isLoading: false,
    showDownloadModal:false,
    showDownloadModalCSV:false,
    // end of filter
    detailCompanyName: "",
    detailCompanyType: "",
    detailProvince: "",
    detailRegency: "",
    detailPeriode: "",
    detailDate: "",
    detailStation: "",
    sensorData: [],
    nameInput:"",
    showAddModal:false,
    showAddSensorModal:false,
    showAddSensor:false,
    showDetailModal:false,
    pagination: {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 20, 50],
      total: 1,
    },
    showDetailModal:false,
    log_as:"",
    listData: [],
  
  };
},
methods: {
    addSensorNow() {
      this.showAddSensor = true
      this.resetSensorInput()
    },
    getData() {
        this.isLoading = true
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        
          API.get(`calibrations?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
            .then(({data}) => {
              if (data.data == null) {
                this.listData = []
              } else {
                console.log(data.data);
                this.listData = data.data
                // this.pagination.total = data.meta.info.count;
              }
                this.isLoading = false
            })
            .catch((err) => {
              Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
              this.isLoading = false
          })
    },
    seeDetail(row) {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        
      API.get(`calibrations/${row.id}` , {headers})
        .then(({data}) => { 
          if (data.data == null) {
            this.sensorData = []
          } else {
            this.detailCompanyName = data.data.comp_name
            this.detailCompanyType = data.data.comp_type_name
            this.detailProvince = data.data.province_name
            this.detailRegency = data.data.kabkot_name
            this.detailPeriode = data.data.quarter
            this.detailDate = data.data.created_at
            this.detailStation = data.data.spot_name
            this.sensorData = data.data.sensors
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false
      })

      this.showDetailModal = true
    },
    getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comp-types', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getProvince() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
       .then(({data:content}) => {
        this.userInput.titikPenaatan = ""
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }

    const queryParams = this.queryParams();


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

  
   

     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    API.get(`calibrations` , { params, headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.pagination.total_items
            this.isLoading = false
          }
      })
      .catch((err) => {
        this.isLoading = false
        console.log(err);
      })
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`stations` , {headers})
      .then(({data:content}) => {
        this.listDataCompliencePoints = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    queryParams(){
      let tmpDate = this.userInput.tanggal.split(" to ");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      const queryParams = {
        page: this.pagination.currentPage,
        page_size: this.pagination.perPage,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };
      return queryParams
    },
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
    },
    tambahUjiKalibrasi() {

      if (!this.userInput.titikPenaatan) {
        return Swal.fire('Pilih titik penaatan','Anda belum memilih titik penaatan','error')
      }

      this.showAddSensorModal = true
      this.showAddModal = false
    },
    batalUjiKalibrasi() {
      this.showAddModal = false
      this.userInput.titikPenaatan = ""
    },
    submitSensor() {
      Swal.fire({
        title: "Tambahkan Sensor?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          this.sensorRegistered.push({
              id: (this.sensorRegistered.length + 1),
              accuration: this.akurasi,
              attachment_file_id: this.idUjiKalibrasiFile,
              brand: this.brand,
              brand_type: this.brandType,
              calibration_date: this.tanggalKalibrasi,
              max: this.max,
              min: this.min,
              parameter: this.sensorType
          })

          this.resetSensorInput()
        }
      });

      this.showAddSensor = false
    },
    willDeleteSensor(row) {
      Swal.fire({
        title: `Hapus Sensor${row.parameter} ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let indexToDelete = this.sensorRegistered.findIndex(
            (tableRow) => tableRow.parameter === row.parameter
          );


          if (indexToDelete >= 0) {
            this.sensorRegistered.splice(indexToDelete, 1);
          }
    

          Swal.fire({
            title: "Berhasil Menghapus",
            text: "Titik Penaatan telah dihapus dari Pendaftaran SPARING",
            icon: "success",
          });
        }
      });
    },
    populatedSensorForUpdate(row) {
      this.idWillGetUpdate = row.id
      this.akurasi = row.accuration
      this.attachment_file_id = this.idUjiKalibrasiFile,
      this.brand = row.brand
      this.brandType = row.brand_type
      this.tanggalKalibrasi = row.calibration_date
      this.max = row.max
      this.min = row.min
      this.sensorType = row.parameter

      this.showAddSensor = true

    },
    sensorForUpdate() {
      let indexToUpdate = this.sensorRegistered.findIndex(
        (tableRow) => tableRow.id === this.idWillGetUpdate
      );

      Swal.fire({
        title: "Perbaharui Sensor ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          this.sensorRegistered[indexToUpdate].id = this.idWillGetUpdate
          this.sensorRegistered[indexToUpdate].accuration = this.akurasi
          this.sensorRegistered[indexToUpdate].brand = this.brand
          this.sensorRegistered[indexToUpdate].brand_type = this.brandType
          this.sensorRegistered[indexToUpdate].calibration_date = this.tanggalKalibrasi
          this.sensorRegistered[indexToUpdate].max = this.max
          this.sensorRegistered[indexToUpdate].min = this.min
          this.sensorRegistered[indexToUpdate].parameter = this.sensorType


          this.showAddSensor = false

          // this.dukunganBrandSensorFile++;
          // this.brosurSpesifikasiSensorFile++;
        }
      });
    },
    resetSensorInput() {
      this.idWillGetUpdate = ''
      this.akurasi = ''
      this.brand = ''
      this.brandType = ''
      this.tanggalKalibrasi = ''
      this.max = ''
      this.min = ''
      this.sensorType = ''
    },
    SubmitujiKalibrasiRegistered() {

      if (this.sensorRegistered.length == 0) {
            return Swal.fire('Sensor belum ditambahkan','untuk melanjutkan uji kalibrasi, anda wajib mengisi sensor','error')
      }

      Swal.fire({
        title: "Tambah Uji Kalibrasi",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {

          

            let payload = {
              logger_id: this.userInput.titikPenaatan,
              sensors: this.sensorRegistered
            }

            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.post(`calibrations`, payload, { headers })
              .then(({ data: content }) => {
                Swal.fire('Pendaftaran Uji Kalibrasi Selesai','','success')
                this.userInput.titikPenaatan = ""
                this.showAddSensorModal = false
                this.sensorRegistered = []
                this.getData()
                this.idUjiKalibrasiFile = ""
              })
              .catch((err) => {
                Swal.fire("Pendaftaran Gagal", "gagal mendaftarkan", "error");
              });
          }
      });
    },
    cancelUjiKalibrasiRegistered() {
      this.showAddSensorModal = false
      this.sensorRegistered = []
      this.userInput.titikPenaatan = ""

    },
    getTimeNow() {
     this.detailDate = moment().format('YYYY-MM-DD');
     return moment().format('YYYY-MM-DD');
    },
    getQuarter() {
      let month = moment().format('YYYY-MM');
      const quarter = moment(month, 'YYYY-MM').quarter();
      return `Q${quarter}`;
    },
    changeSizePage() {
      this.getData();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData();
    },
    // end New
    
    
    getDataDownload() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        is_export: true,
        status:this.filterStatusPendaftaran,
        start_date: startDate,
        end_date:endDate,
        province_id:this.form.umum.compProvince,
        regency_id:this.form.umum.compCity,
        // company_id:this.userInput.companyName,
        company_type_id:this.userInput.companyType,
        sparing_compliance_point_id:this.userInput.titikPenaatan
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


        API.get(`companies/uji-kalibrasi` , { params, headers})
          .then(({data}) => {
            if (data.data == null) {
                this.listDataDownload = []
                this.isLoading = false
              } else {
                this.listDataDownload = data.data
                this.pagination.total = data.meta.info.count
                setTimeout(() => {
                  this.isLoading = false
                }, 200);
              }
          })
          .catch((err) => {
            this.isLoading = false
            console.log(err);
          })
    },
    downloadPDFNow() {
      this.getDataDownload()
      this.showDownloadModal = true      
    },
    downloadExcelNow() {
      this.getDataDownload()
      this.showDownloadModalCSV = true
    },
    async print() {
      try {
        // Dapatkan data dari objek
        const data = this.listDataDownload;

        // Buat dokumen jsPDF
        const doc = new jsPDF();

        // Tambahkan header
        const header = [
          "No",
          "Nama Industri",
          "Waktu",
          "PH",
          "COD (MG/L)",
          "TSS (MG/L)",
          "NH3N (MG/L)",
          "DEBIT (MG3/MENIT)",
          "Beban COD",
          "Beban TSS",
          "Beban NH3N",
        ];

        let headerPemenuhan = [
                "No",
                "Pemantauan terus menerus dalam jaringan",
                "Jumlah Data yang Memenuhi Baku Mutu Air Limbah",
                "Presentase Pemenuhan Baku Mutu Air Limbah"
        ];
        let compName = ""
        let compAddress = ""
        let compLongitude = ""
        let compLatitude = ""
        let compTlp = ""
        let userEmail = ""
        let compType = ""
        let compSumberLimbah = ""
        let compTeknologi = ""
        let compNoIzin = ""
        let compInstansi = ""
        let compTglIzin = ""

        
        // Tambahkan baris data
        const rows = data.map((item, index) =>
          [
          index + 1,
          item.company_name,
          item.timestamp,
          item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan",
          item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan",
          item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan",
          item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan",
          item.debit ? item.debit.toFixed(5) : "Tidak Diwajibkan",
          item.beban_cod ? item.beban_cod.toFixed(5) : "Tidak Diwajibkan",
          item.beban_tss ? item.beban_tss.toFixed(5) : "Tidak Diwajibkan",
          item.beban_nh3n ? item.beban_nh3n.toFixed(5) : "Tidak Diwajibkan",
        ]);

        data.map((item,index)=>{
          compName = item.company_name
          compAddress = "{{item.company_name}}"
          compLongitude = "{{item.longitude}}"
          compLatitude = "{{item.latitude}}"
          compTlp = "item.compTlp"
          userEmail = "item.userEmail"
          compType = item.company_type_name
          compSumberLimbah = "{{item.sumberLimbah}}"
          compTeknologi = "{{item.teknologi}}"
          compNoIzin = "{{item.noIzin}}"
          compInstansi = "{{item.instansi}}"
          compTglIzin = "{{item.tglIzin}}"
        })


        let bodyProfile = [
                ["NAMA INDUSTRI", compName],
                ["ALAMAT", compAddress],
                ["LATITUDE", compLongitude],
                ["LONGITUDE", compLatitude],
                ["NOMOR TELEPON", compTlp],
                ["EMAIL", userEmail],
                ["JENIS USAHA", compType],
                ["SUMBER LIMBAH", compSumberLimbah],
                ["TEKNOLOGI PENGOLAHAN LIMBAH", compTeknologi],
                ["NO. IZIN", compNoIzin],
                ["INSTANSI PENERBIT", compInstansi],
                ["TANGGAL TERBIT IZIN", compTglIzin]
        ];

        
        const logo = new Image();
        logo.src = '/img/brand/img-logo-color.png';

        // Tambahkan informasi profil di atas judul
        doc.addImage(logo, 'JPEG', 10, 10, 20, 20);
        doc.text("Kementerian Lingkungan Hidup", 35, 20, { lineHeight: 15 }); // Sesuaikan nilai lineHeight sesuai kebutuhan

        // Tambahkan judul
        doc.setFontSize(11);
        doc.text("Laporan Perjam", 150, 20);
        doc.setTextColor(90, 90, 90)
        doc.setFont("bold");
        doc.text("Data ini dibuat pada 12-20-2024", 150, 26);
        doc.setFont("normal");

      
        doc.autoTable({
          theme: "plain",
          body: bodyProfile,
          startY: 45, // Mulai di bawah judul profil
          columnStyles: {
            0: {
              fontStyle: "bold",
              halign: "right",
              cellWidth: 100,
              fillColor: [246, 249, 252],
            },
            1: { cellWidth: 165 },
          },
          styles: {
            cellPadding: 2,
            textColor: [41, 102, 75],
            // font: "times",
            lineWidth: 0.1,
            lineColor: [240, 240, 240],
            halign: "left",
          },
        });

        // Tambahkan tabel data di bawah informasi profil
        doc.autoTable({
          head: [header],
          theme: "striped",
          body: rows,
          headStyles: {
            fillColor: [41, 102, 75],
          },
          styles: {
            lineWidth: 0.2,
            halign: "center",
          },
        });

        doc.addPage();

          doc.autoTable({
                  head: [headerPemenuhan],
                  theme: "striped",
                  body: [['1','pH',3,100],['2','COD',3,100],['3','TSS',3,90],['4','NH3N',0,'Tidak Diwajibkan'],['5','Debit',3,100]],
                  headStyles: {
                    fillColor: [41, 102, 75]
                  },
                  styles: {
                    lineWidth: 0.2,
                    halign: "center"
                  },
          });

        // Simpan dokumen PDF
        doc.save("exportData.pdf");

        Swal.fire("Data di Unduh", "", "success");
        this.showDownloadModal = false;
      } catch (error) {
        console.error("Error:", error);
        // Tambahkan penanganan kesalahan jika diperlukan
      }
    },
    ExportExcel(type, fn, dl) {
        var elt = this.$refs.exportable_table;
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModalCSV = false
        return dl ?
            XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
            XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
    },
    reportTableState() {
        return []
    },
    // End of Filter
    
    
    closeModal() {
      this.showDetailModal = false
    },
    handleAdd() {
      this.showAddModal = true
    },
    doAdd() {

    },
    
    uploadFile(field){
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.uploading = true
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data
          let value = data.id
          // this.$set(this.userInput, field, value);
          this.idUjiKalibrasiFile = value
          // console.log(`${data}:`, this.userInput[field]);
          this.uploading = false;
        })
        .catch((err) => {
          this.uploading = false;
          Swal.fire(
                  "Terjadi Kesalahan",
                  "Mohon upload ulang kembali",
                  "error"
          );
        });
    },
  },
};
</script>


<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
  details {
    border: 2px solid rgb(215, 215, 215);
    border-radius: 10px;
    padding: 0.6rem 1.5rem 0.25rem;
    margin-bottom: 1rem;
  }
  summary {
    font-weight: bold;
    margin: 0 -1.5rem 0.75rem;
    padding: 0.6rem 1.5rem 0.25rem;
    user-select: none;
  }
  details[open] {
    padding: 0.6rem 1.5rem 0.25rem;
  }
  details[open] summary {
    border-bottom: 2px solid rgb(215, 215, 215);
    margin-bottom: 1.5rem;
    padding: 0.6rem 1.5rem 1.25rem;
  }
</style>
  
  